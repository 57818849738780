@function dynamic-text-color($color) {
  @if (lightness( $color ) > 40) {
    @return darken($color, 40);
  } @else {
    @return lighten($color, 40);
  }
}

body {
  font-size: ($font-size-base + 1);
  font-weight: 300;
  margin-top: 80px;
}

.page-title {
  margin-top: 2px;
}

.block {
  display: block;
  margin-bottom: 40px;
  padding: 20px 40px 60px 40px;
  border: 1px solid lighten($well-bg, 5%);
  background-color: $well-bg;
  :after {
    clear: both;
  }
}


.block-box {
  text-align: center;
  min-height: 200px;
  padding: 60px 0;
  .glyphicon {
    font-size: 48px;
    padding: 10px;
  }
}

.block-image {
  margin-bottom: 20px;
  background-color: lighten($well-bg, 2%);
  img {
    max-width: 100%;
  }
  h2, h3 {
    margin: 0;
    padding: 4px;
    font-size: 18px;

  }
  p {
    padding: 4px;
    font-size: 14px;
  }
}

.footer-top {
  padding: 10px 0;
  font-family: 'Amatic SC', cursive;
  color: dynamic-text-color($navbar-default-bg);
  background: $navbar-default-bg;
  vertical-align: middle;
}

.footer-right {
  float: right;
  clear: right;
  padding-bottom: 1.5em;
  font-family: 'Amatic SC', cursive;
}

.icon { /* Customizes all icons at once */
  display: inline-block;
}


.footer-bottom {
  margin: 10px 0;
}

.glyphicon-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.shorten-output {
  margin-top: 20px;
  font-size: $font-size-large;
}

.panel-heading h1 {
  margin: 2px 0;
  font-size: $font-size-h3;
}

.btn_delete_image {
  position: absolute;
  bottom: 68px;
  right: 10px;
  @include opacity(0.8);
}

.control-label {

  color: #101756;
}

.btn-link {
  color: #101756;
}

.btn-link:hover{
  color: #101756;
}

#checkbox {
  color: #101756;
}

.checkbox label {
  color: #101756;
}

h3 a {
    color: #101756;
  }

h3 a:hover {
  color: #101756;
}
.text-right {
  font-size: x-large;
  font-family: 'Amatic SC', cursive;
}

b, strong {
  color: #101756;
}