body {
    color: #5a5a5a;
}

.page-title, h1, .h1, h1, h2, .h2 {
    color: #ff2b55;
}

.panel {
    border: 7px solid #ecf0f1;
    border-radius: 4px;
    box-shadow: 0 1px 1px #02dfcb;
}

b, strong {
/*    color: #5a5a5a;*/
}

.form-control, input {
    color: slategray;
}

.block {
    border:0px;
    border-bottom: 1px solid #02dfcb;
    background-color: #ecf0f1;
    border-bottom: 2px solid #9AFFFF;
    border-bottom-left-radius: 10px;
}

.btn-primary {
    background-color: #95a5a6;
    border-color: #95a5a6;
}

.btn-primary:active:hover, .btn-primary:hover  {
    background-color: #343333;
    border-color: #343333;
}

.btn-info, .btn-danger {
    background-color: #DC143C;
    border-color: #ff5ca7;
}

.btn-danger:hover {
    background-color: #DC143C;
    border-color: #DC143C;
}

.btn-warning {
    background-color: #f9b418;
    border-color: #F39C12;
}

.navbar-nav > .btn-warning {
    margin-top:7px;
}

.navbar-brand {
    line-height: 0.3;
    font-size: 3em; 
    font-family: 'Amatic SC', cursive;
}

.modal-title {
    color: #ff2b55;
}