// Variables
@import "variables";

// Bootstrap
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
//@import "node_modules/bootswatch/flatly/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootswatch/flatly/bootswatch";

// FontAwesome
$fa-font-path:        "~font-awesome/fonts";
@import "node_modules/font-awesome/scss/font-awesome";

// Fine-Uploader
@import "node_modules/fine-uploader/fine-uploader/fine-uploader";
@import "node_modules/fine-uploader/fine-uploader/fine-uploader-new";
@import "node_modules/fine-uploader/fine-uploader/fine-uploader-gallery";

@import "node_modules/sweetalert2/src/sweetalert2";

@import "imagehost";

@import "custom";
